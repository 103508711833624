<template>
  <div>
    <label :for="`input${_uid}`" class="timeComponent">
      <span class="placeholder">{{ title }}</span>
      <the-mask
        :id="`input${_uid}`"
        :placeholder="placeholder"
        class="time"
        :mask="masks"
        v-model.trim="value"
        :masked="true"
      />
    </label>
    <div class="tip" v-if="showError && validate.$invalid">
      {{ $t("fieldErrors.selectTime") }}
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
export default {
  name: "TimePicker",
  components: {
    TheMask
  },
  props: {
    placeholder: {
      type: String,
      default: "placeholder"
    },
    showError: {
      type: Boolean,
      default: true
    },
    propname: {
      type: String,
      default: ""
    },
    prevalue: {
      type: [String],
      default: null
    },
    validate: {
      type: Object
    },
    title: {}
  },
  data() {
    return {
      dateShown: false,
      value: "",
      initialDate: null,
      focusDate: new Date()
    };
  },
  watch: {
    value(newValue) {
      this.$emit("input", newValue);
    }
  },
  computed: {
    masks() {
      return ["##:##"];
    }
  },
  mounted() {
    if (this.prevalue) {
      this.value = this.prevalue;
    }
  }
};
</script>

<style lang="sass" scoped>
.timeComponent
  height: 50px
  background-color: #fff
  box-shadow: 0 0 0 1px inset #e9ebf4
  display: flex
  justify-content: space-between
  align-items: center
  padding: 15px
  cursor: pointer
  position: relative
  .time
    // font-style: normal
    // font-weight: 500
    // font-size: 15px
    // line-height: 130%
    // letter-spacing: 0.01em
    // color: #111325
    cursor: pointer
    outline: none
    border: none
    // width: 100px
    font-weight: 400
    font-size: 16px
    line-height: 19px
    color: #28292F
    position: absolute
    bottom: 8px
    left: 16px
    padding: 0
    width: 80%
    &:placeholder
      color: #ABA7A7
  .placeholder
    font-weight: 400
    font-size: 12px
    line-height: 100%
    color: #28292F
    position: absolute
    top: 8px
    left: 16px
.tip
  color: #ff2e2e
</style>
