<template>
  <div class="inputComponent" :class="styleClass">
    <label
      :for="`input${_uid}`"
      class="wrpimp"
      :class="{ isError: isError, active: isFocus, labelmode: title}"
    >
      <span class="placeholder" v-if="title">{{ title }}</span>
      <template v-if="!mask">
        <input
          :id="`input${_uid}`"
          :type="itype ? itype : 'text'"
          v-model.trim="value"
          @keyup="onKeyUp"
          :name="propname"
          @focus="onFocus"
          @blur="onBlur"
          @input="handlerFunc"
          :placeholder="placeholder"
        />
      </template>
      <template v-else>
        <the-mask
          :id="`input${_uid}`"
          :mask="mask ? mask : ''"
          :masked="true"
          v-model.trim="value"
          @keyup.native="onKeyUp"
          :name="propname"
          @focus.native="onFocus"
          @blur.native="onBlur"
        />
      </template>
      <!-- <label
        :for="`input${_uid}`"
        class="labeltxt"
        :class="{ active: value != '' || isFocus }"
        >{{ placeholder }}</label
      > -->
    </label>
    <span class="tip" v-if="isError">{{ isError }}</span>
  </div>
</template>

<script>
import defaultinput from "./DefaultInputMixin.js";
export default {
  props: ["styleClass", "pattern"],
  name: "DefaultInput",
  mixins: [defaultinput]
};
</script>

<style lang="sass" scoped>
.inputComponent
  .wrpimp
    height: 50px
    width: 100%
    background-color: #fff
    display: block
    input
      height: 50px
      width: 100%
      border: none
      outline: none
      padding: 0 16px
      box-sizing: border-box
      display: block

      font-style: normal
      font-weight: 400
      font-size: 16px
      line-height: 19px
      color: #28292F

      &::placeholder
        color: #ABA7A7
    
    &.labelmode
      position: relative
      input
        position: absolute
        bottom: 8px
        left: 16px
        padding: 0
        background: transparent
        height: unset
        width: 80%
      .placeholder
        font-weight: 400
        font-size: 12px
        line-height: 100%
        color: #28292F
        position: absolute
        top: 8px
        left: 16px





// .inputComponent {
// 	margin: 35px 0 20px;
// 	border: unset;

// 	.wrpimp {
// 		box-shadow: 0 -1px 0 0px inset $bd1;
// 		// background-color: #fff;
// 		position: relative;
// 		display: flex;
// 		box-sizing: border-box;
// 		transition: 0.3s ease;
// 		padding: 26px 0 8px;
// 		&.active {
// 			box-shadow: 0 -2px 0 0px inset $bb;
// 		}
// 	}
// 	input {
// 		height: 24px;
// 		width: 100%;
// 		border: none;
// 		background-color: transparent;
// 		outline: none;
// 		padding: 0;
// 		box-sizing: content-box;
// 		display: block;

// 		@include t3;

// 		&::placeholder {
// 			color: blue;
// 		}
// 	}
// 	.labeltxt {
// 		pointer-events: none;
// 		position: absolute;
// 		top: 26px;
// 		left: 0px;
// 		@include t3;
// 		color: #111325;
// 		transition: 0.1s ease;
// 		&.active {
// 			top: 0px;
// 			left: 0px;
// 			transform: unset;
// 			color: $bd2;
// 			@media (max-width: $m) {
// 				// top: unset;
// 				// bottom: 100%;
// 			}

// 			font-style: normal;
// 			font-weight: 500;
// 			font-size: 14px;
// 			line-height: 130%;
// 			letter-spacing: 0.02em;
// 			color: #585b6d;
// 		}
// 	}
// 	.isError {
// 		box-shadow: 0 -2px 0 0px inset $red;
// 		.labeltxt {
// 			color: $red;
// 		}
// 	}
// }
.tip
  color: #ff2e2e
  margin: 2px 0 0


// .blue {
// 	.wrpimp {
// 		box-shadow: 0 -1px 0 0px inset $bl2;
// 		&.active {
// 			box-shadow: 0 -2px 0 0px inset #fff;
// 		}
// 	}
// 	input {
// 		color: #fff;
// 	}
// 	.labeltxt {
// 		color: $bl2;
// 		&.active {
// 			color: $bl2;
// 			// color: #585b6d;
// 		}
// 	}
// 	.isError {
// 		box-shadow: 0 -2px 0 0px inset $red2;
// 		.labeltxt {
// 			color: $red2;
// 		}
// 	}
// 	.tip {
// 		color: $red2;
// 	}
// }
</style>
