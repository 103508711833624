<template>
  <div class="inputComponent">
    <span class="wrpimp" :class="{ isError: isError, active: isFocus }">
      <input
        :id="`input${_uid}`"
        v-model.trim="value"
        :name="propname"
        @keyup="onKeyUp"
        @focus="onFocus"
        @blur="onBlur"
        autocomplete="off"
        :placeholder="placeholder"
      />
      <label
        :for="`input${_uid}`"
        class="placeholder"
        :class="{ active: value != '' || isFocus }"
        >{{ title }}</label
      >
    </span>
    <i class="ic-dropdown" :class="{ active: ddFocus }"></i>
    <!-- <span class="ic-todown" :class="{ active: ddFocus }"></span> -->
    <span class="tip" v-if="isError">{{ isError }}</span>
    <div class="dropdown" v-if="ddFocus">
      <template v-if="filteredItems.length > 0">
        <div
          class="item"
          v-for="(item, index) in filteredItems"
          :key="index"
          @click="onItemClick(index)"
        >
          {{ item }}
        </div>
      </template>
      <div v-else class="item empty">{{ $t("searchfail") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectInput",
  props: [
    "styleClass",
    "placeholder",
    // "errors",
    "items",
    "propname",
    "validate",
    "showError",
    "wrpstyle",
    "mode",
    "prevalue",
    "title"
  ],
  data() {
    return {
      value: "",
      labelWidth: 0,
      isFocus: false,
      filter: false,

      ddTimer: null,
      ddFocus: false
    };
  },
  methods: {
    onKeyUp() {
      this.filter = true;
    },
    onBtnClc() {
      if (this.mode == "button") {
        this.$emit("submit");
      }
    },
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.filter = false;
      this.isFocus = false;
      let a = null;
      for (const key in this.items) {
        if (Object.hasOwnProperty.call(this.items, key)) {
          const item = this.items[key];
          if (item.toLowerCase() == this.value.toLowerCase()) {
            a = item;
          }
        }
      }
      if (a) {
        this.$emit("input", a);
      } else {
        this.value = "";
        this.$emit("input", "");
      }
    },
    onItemClick(index) {
      this.ddFocus = false;
      this.value = this.items[index];
      this.$emit("input", this.value);
    }
  },
  computed: {
    isMaskedInput() {
      if (this.mask !== undefined && this.mask !== null && this.mask !== "")
        return true;
      else return false;
    },
    isError() {
      if (this.validate) {
        if (this.validate.$invalid && this.showError) {
          if (this.validate.required === false) {
            return this.$t("fieldErrors.required");
          }
          if (this.validate.minLength === false) {
            return this.$t("fieldErrors.minLength", {
              length: this.validate.$params.minLength.min
            });
          }
          if (this.validate.email === false) {
            return this.$t("fieldErrors.email");
          }
          return "some error";
        } else return null;
      } else return null;
    },
    btnClass() {
      if (this.mode == "button") {
        if (this.value.trim() != "") {
          return "active";
        } else return "";
      } else return "";
    },
    filteredItems() {
      if (this.value != "" && this.filter) {
        let a = [];
        for (const key in this.items) {
          if (Object.hasOwnProperty.call(this.items, key)) {
            const item = this.items[key];
            if (item.toLowerCase().match(this.value.toLowerCase()))
              a.push(item);
          }
        }
        return a;
      } else {
        return this.items;
      }
    }
  },
  watch: {
    isFocus(newValue) {
      if (newValue === true) {
        clearTimeout(this.ddTimer);
        this.ddFocus = true;
      } else if (newValue === false) {
        this.ddTimer = setTimeout(() => {
          this.ddFocus = false;
        }, 200);
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.value = this.prevalue ? this.prevalue : "";
    }, 200);
  }
};
</script>

<style lang="sass" scoped>
.inputComponent
  // margin: 35px 0 20px
  border: unset
  position: relative
  .wrpimp
    // box-shadow: 0 -1px 0 0px inset $bd1
    // background-color: #fff
    position: relative
    display: flex
    box-sizing: border-box
    transition: 0.3s ease
    height: 50px
    background-color: #fff
    // padding: 26px 0 8px
    &.active
      // box-shadow: 0 0 0 2px inset $c-btn


  input
    // height: 24px
    // width: 100%
    border: none
    background-color: #fff
    outline: none
    padding: 0
    box-sizing: content-box
    display: block
    padding-right: 35px

    font-weight: 400
    font-size: 16px
    line-height: 19px
    color: #28292F
    position: absolute
    bottom: 8px
    left: 16px
    padding: 0
    display: block
    width: 80%
    &::placeholder
      color: #ABA7A7

  .placeholder
    font-weight: 400
    font-size: 12px
    line-height: 100%
    color: #28292F
    position: absolute
    top: 8px
    left: 16px


  .ic-dropdown
    font-size: 24px
    position: absolute
    top: 50%
    right: 5px
    // color: $bd1
    transition: 0.3s ease
    transform: translate(0, -50%)
    &.active
      transform: translate(0, -50%) rotate(180deg)


  .labeltxt
    pointer-events: none
    position: absolute
    top: 26px
    left: 0px
    // @include t3
    color: #111325
    transition: 0.1s ease
    &.active
      top: 0px
      left: 0px
      transform: unset
      // color: $bd2
      // @media (max-width: $m)
        // top: unset
        // bottom: 100%


      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 130%
      letter-spacing: 0.02em
      color: #585b6d


  .isError
    // box-shadow: 0 -2px 0 0px inset red
    .labeltxt
      color: red



.dropdown
  position: absolute
  top: calc(0% + 100%)
  left: 0
  z-index: 10
  width: 100%
  border: 1px solid #4AA7BC
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)
  .item
    box-sizing: border-box
    padding: 5px 10px
    width: 100%
    cursor: pointer
    background-color: #fff
    &.empty
      color: #ABA7A7

    &:hover
      background: #C1DBE1


  // .item:nth-child(even)
    // background-color: rgb(243, 243, 243)

  // .item:nth-child(odd)
    // background-color: green


.tip
  // @include t6
  color: #ff2e2e
  margin: 2px 0 0
</style>
