<template>
  <div class="root" :class="`component${_uid}`">
    <label
      :for="`input${_uid}`"
      class="dateComponent"
      ref="spn"
      @click="onPictogramClick"
    >
      <!-- <the-mask
				:id="`input${_uid}`"
				:placeholder="placeholder"
				class="date"
				:mask="masks"
				v-model.trim="value"
				:masked="true"
				@click.native="onFocus"
			/> -->
      <span class="placeholder">{{ title }}</span>
      <span class="spanval" :class="{ p: !valStr }">{{
        valStr || placeholder
      }}</span>
      <i class="ic-calender"></i>
    </label>
    <div class="tip" v-if="showError && validate.$invalid">
      {{ $t("fieldErrors.selectDate") }}
    </div>
    <date-select
      v-if="dateShown"
      class="date_select"
      :focusDate="focusDate"
      :initialDate="initialDate"
      @select="onDpChange"
    ></date-select>
  </div>
</template>

<script>
// import { TheMask } from "vue-the-mask";
import DateSelect from "./calendar/DateSelect.vue";
export default {
  components: {
    DateSelect
    // TheMask
  },
  name: "DatePicker",
  props: {
    placeholder: {
      type: String,
      default: "placeholder"
    },
    showError: {
      type: Boolean,
      default: true
    },
    propname: {
      type: [String, Object],
      default: ""
    },
    prevalue: {
      type: [Date, String],
      default: null
    },
    validate: {
      type: Object,
      default: null
    },
    title: {}
  },
  data() {
    return {
      dateShown: false,
      value: "",
      rawDate: null,
      initialDate: null,
      focusDate: new Date(),

      mobTop: null
    };
  },
  watch: {
    value(newValue) {
      let s = newValue.split(".");
      if (s && s.length == 3 && s[2].length == 4) {
        let dateStr = `${s[1]}.${s[0]}.${s[2]}`;
        let d = new Date(dateStr);
        if (Object.prototype.toString.call(d) === "[object Date]") {
          if (isNaN(d.getTime())) {
            this.onChange("invalid date");
          } else {
            this.initialDate = d;
            this.focusDate = d;
            this.onChange(d);
          }
        }
      } else if (newValue == "") {
        this.onChange(null);
      } else {
        this.onChange("invalid date");
      }
    }
  },
  computed: {
    masks() {
      return ["##.##.####"];
    },
    valStr() {
      if (this.rawDate) {
        const num = Intl.DateTimeFormat(this.$i18n.locale, {
          day: "numeric"
        }).format(this.rawDate);
        const month = Intl.DateTimeFormat(this.$i18n.locale, {
          month: "long"
        }).format(this.rawDate);
        const weekday = this.$t(`calendarDays.${this.rawDate.getDay() + 1}`);
        return `${num} ${month}, ${weekday.toLowerCase()}`;
      } else return null;
    }
  },
  methods: {
    onClickOutside(e) {
      const t = e.target;
      const condition =
        t.classList.contains(`component${this._uid}`) ||
        t.closest(`.component${this._uid}`);
      if (!condition) {
        this.dateShown = false;
        document.removeEventListener("click", this.onClickOutside);
      }
    },
    onPictogramClick() {
      setTimeout(() => {
        this.dateShown = !this.dateShown;
        if (this.dateShown)
          document.addEventListener("click", this.onClickOutside);
        else document.removeEventListener("click", this.onClickOutside);
      }, 100);
    },
    onScroll() {
      if (this.dateShown) {
        this.mobTop = this.$refs.spn.getBoundingClientRect().top + 60;
      }
    },
    onFocus() {
      if (window.innerWidth <= 600) {
        this.mobTop = this.$refs.spn.getBoundingClientRect().top + 60;
      }
      this.dateShown = true;
      document.body.addEventListener("click", this.onClick);
    },
    onClick(e) {
      let trgt = e.target;
      if (
        !(
          trgt.classList.contains("dateComponent") ||
          trgt.closest(".dateComponent")
        )
      ) {
        this.dateShown = false;
        document.body.removeEventListener("click", this.onClick);
      }
    },
    onChange(e) {
      this.$emit("input", e);
    },
    onDpChange(e) {
      this.rawDate = e;
      this.value = `${Intl.DateTimeFormat(this.$i18n.locale, {
        day: "2-digit"
      }).format(new Date(e))}.${Intl.DateTimeFormat(this.$i18n.locale, {
        month: "numeric",
        minimumIntegerDigits: 3
      }).format(new Date(e))}.${Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric"
      }).format(new Date(e))}`;

      this.dateShown = false;
      document.removeEventListener("click", this.onClickOutside);
    }
  },
  destroyed() {
    // document.removeEventListener("scroll", this.onScroll);
    document.removeEventListener("click", this.onClickOutside);
  },
  mounted() {
    // window.addEventListener("scroll", this.onScroll);
    if (this.prevalue) {
      this.value = `${Intl.DateTimeFormat(this.$i18n.locale, {
        day: "2-digit"
      }).format(this.prevalue)}.${Intl.DateTimeFormat(this.$i18n.locale, {
        month: "numeric",
        minimumIntegerDigits: 3
      }).format(this.prevalue)}.${Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric"
      }).format(this.prevalue)}`;
    }
  }
};
</script>

<style lang="sass" scoped>
// .spanitem
//   height: 50px
//   width: 100%
//   display: block
//   background-color: #fff
.root
  position: relative
.dateComponent
  height: 50px
  background-color: #fff
  box-shadow: 0 0 0 1px inset #e9ebf4
  display: block
  align-items: center
  padding: 16px
  position: relative
  cursor: pointer
  .date
    font-style: normal
    font-weight: 500
    font-size: 15px
    line-height: 130%
    letter-spacing: 0.01em
    color: #111325
    cursor: pointer
    outline: none
    border: none
  .ic-calender
    cursor: pointer
    font-size: 15px
    position: absolute
    top: 50%
    right: 16px
    transform: translate(0, -50%)
    color: #ABA7A7 !important
  .placeholder
    font-weight: 400
    font-size: 12px
    line-height: 100%
    color: #28292F
    position: absolute
    top: 8px
    left: 16px
  .spanval
    font-weight: 400
    font-size: 16px
    line-height: 19px
    color: #28292F
    position: absolute
    bottom: 8px
    left: 16px
    &.p
      color: #ABA7A7

.date_select
  position: absolute
  top: 100%
  z-index: 100
  left: 0
  @media (max-width: 768px)
    left: 0
    right: 0
    width: 100%

.tip
  margin-top: 3px
  color: #ff2e2e
</style>
